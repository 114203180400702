import React, {Component} from "react";
import {PrimaryInput,PrimaryTextarea} from "../Input.style";
import Checkbox from "../Checkbox";
import InputNumber from "../InputNumber";
import ColorPicker from "../ColorPicker";
class AdminBefore extends Component{
    render(){
        const {toggleDropdown,isOpenBeforeBody,action,texts,radiochange,getMarginSelector,column,incrementSpeed,decrementSpeed,stopCounter,photoUpload,images,addElement,getTextStyle,withQuestionForm,changeTitle,handleChange,position} = this.props;
        const ImgUpload =({
            onChange,
            src,
            id
          })=>
            <label htmlFor={'photo-upload-'+id} className="custom-file-upload fas">
              <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src} alt=""/>
              </div>
              <input id={'photo-upload-'+id} type="file" onChange={onChange}/> 
            </label>
        return(
        <div className='dropdown'>
            <div className='dropdown-header' onClick={()=>toggleDropdown(isOpenBeforeBody,"contenu-before")}>
            Contenu de la page
            <i className={`fa fa-chevron-right icon ${isOpenBeforeBody ? 'open' : ''}`}></i>
            </div>
            <div className={!isOpenBeforeBody? 'dropdown-body' : 'dropdown-body open'}>
                <div className="row border-bottom-white">
                    <h4>Couleur de fond du contenu</h4>
                    <ColorPicker action={action} details={texts[18]}/>
                    <h4>Alignement horizontal du contenu</h4>
                    <div className="style">
                    <div className="align">
                        <input type="radio" value="flex-start" id="gauche" name="align" onChange={(e) => radiochange(e,"align-before")} checked={texts[18].font =="flex-start" ? true : false}/>
                        <label htmlFor="gauche">Gauche</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="center" id="center" name="align" onChange={(e) => radiochange(e,"align-before")}/>
                        <label htmlFor="center">Centrer</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="flex-end" id="droite" name="align" onChange={(e) => radiochange(e,"align-before")}/>
                        <label htmlFor="droite">Droite</label>
                    </div>
                    </div>
                    <h4>Alignement vertical du contenu</h4>
                    <div className="style">
                    <div className="align">
                        <input type="radio" value="flex-start" id="top" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-before")} checked={texts[18].weight =="flex-start" ? true : false}/>
                        <label htmlFor="top">En haut</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="center" id="middle" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-before")}/>
                        <label htmlFor="middle">Au milieu</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="flex-end" id="bottom" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-before")}/>
                        <label htmlFor="bottom">En bas</label>
                    </div>
                    </div>
                    <h4>Marge du contenu</h4>
                    {getMarginSelector(18)}
                    <h4>Format de la page</h4>
                    <div className="style deux">
                    <div className="color">
                        <input type="radio" value="1" id="one" name="column-before" onChange={(e) => radiochange(e,"column-before")} checked={column ==1 ? true : false}/>
                        <label htmlFor="one">Une colonne</label>
                    </div>
                    <div className="color">
                        <input type="radio" value="2" id="two" name="column-before" onChange={(e) => radiochange(e,"column-before")}/>
                        <label htmlFor="two">Deux colonnes</label>
                    </div>
                    </div>
                </div>
                {column == 2 ?
                <div className="row">
                    <h4>Largeur des colonnes (en %)</h4>
                    <div className="style deux">
                        <div className="color">
                            <p>Gauche</p>
                            <InputNumber incr={() => incrementSpeed(texts[17].id,"left-column")} decr={() => decrementSpeed(texts[17].id,"left-column")} stop={stopCounter} width={texts[17].ml}/>
                        </div>
                        <div className="size">
                            <p>Droite</p>
                            <InputNumber incr={() => incrementSpeed(texts[17].id,"right-column")} decr={() => decrementSpeed(texts[17].id,"right-column")} stop={stopCounter} width={texts[17].mr}/>
                        </div>
                    </div>
                    <div className="row">
                    <h4>Placer l'image sur la colonne de:</h4>
                        <div className="style deux">
                            <div className="color">
                                <input type="radio" value="1" id="left" name="place" onChange={(e) => radiochange(e,"position-before")}/>
                                <label htmlFor="left">Gauche</label>
                            </div>
                            <div className="color">
                                <input type="radio" value="2" id="right" name="place" onChange={(e) => radiochange(e,"position-before")} checked={position ==2 ? true : false}/>
                                <label htmlFor="right">Droite</label>
                            </div>
                        </div>
                    </div>
                    <h4>L'image</h4>
                    <ImgUpload onChange={(e) => photoUpload(e,3)} src={images[3].imagePreviewUrl} id={images[3].id}/>
                </div> : "" }
                <Checkbox text="Ajouter un formulaire pour poser des questions?" action={() => addElement(withQuestionForm,"add-questionBeforeForm")} id="add-questionBeforeForm" checked={!withQuestionForm?true:false}/>
                {!withQuestionForm ? 
                <div className="row border-bottom-white">
                    <p>Texte pour pour poser une question</p>
                    <PrimaryTextarea placeholder="texte pour une question', (ex:posez votre question,..)" id={texts[17].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(17)}
                    <p className="mt-1">Texte pour le bouton d'envoi une question</p>
                    <PrimaryInput placeholder="ex:envoyer, valider,..." id={texts[18].id} onChange={(e) => changeTitle(e)}/>
                </div>
                : null}
                {!withQuestionForm ?
                <div class="row">
                    <h4>Style des boutons et champs</h4>
                    <h5>Champ</h5>
                    <div className="style mb-1">
                        <div className="color">
                            <p>Couleur</p>
                            <ColorPicker action={handleChange} details={texts[8]}/>
                        </div>
                        <div className="size">
                            <p>taille</p>
                            <InputNumber incr={() => incrementSpeed(texts[8].id,"width")} decr={() => decrementSpeed(texts[8].id,"width")} stop={stopCounter} width={texts[8].size}/>
                        </div>
                    </div>
                    <h5>Bouton</h5>
                    <div className="style">
                    <div className="color">
                        <p>Couleur</p>
                        <ColorPicker action={action} details={texts[9]}/>
                    </div>
                    <div className="size">
                        <p>taille</p>
                        <InputNumber incr={() => incrementSpeed(texts[9].id,"width")} decr={() => decrementSpeed(texts[9].id,"width")} stop={stopCounter} width={texts[9].size}/>
                    </div>
                    </div>
                </div>
                :null}
            </div>
        </div>
        )
    }
}
export default AdminBefore