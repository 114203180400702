import React, {Component} from "react";
import {PrimaryTextarea} from "../Input.style";
import InputNumber from "../InputNumber";
import ColorPicker from "../ColorPicker";
class AdminAfter extends Component{
    render(){
        const {toggleDropdown,isOpenBodyAfter,action,texts,radiochange,getMarginSelector,column,incrementSpeed,decrementSpeed,stopCounter,photoUpload,images,getTextStyle,changeTitle} = this.props;
        const ImgUpload =({
            onChange,
            src,
            id
          })=>
            <label htmlFor={'photo-upload-'+id} className="custom-file-upload fas">
              <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src} alt=""/>
              </div>
              <input id={'photo-upload-'+id} type="file" onChange={onChange}/> 
            </label>
        return(
        <div className='dropdown'>
            <div className='dropdown-header' onClick={()=>toggleDropdown(isOpenBodyAfter,"contenu-after")}>
            Contenu de la page
            <i className={`fa fa-chevron-right icon ${isOpenBodyAfter ? 'open' : ''}`}></i>
            </div>
            <div className={!isOpenBodyAfter? 'dropdown-body' : 'dropdown-body open'}>
                <div className="row border-bottom-white">
                    <h4>Couleur de fond du contenu</h4>
                    <ColorPicker action={action} details={texts[16]}/>
                    <h4>Alignement horizontal du contenu</h4>
                    <div className="style">
                        <div className="align">
                            <input type="radio" value="flex-start" id="gauche" name="align-after" onChange={(e) => radiochange(e,"align-after")}/>
                            <label htmlFor="gauche">Gauche</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="center" id="center" name="align-after" onChange={(e) => radiochange(e,"align-after")}/>
                            <label htmlFor="center">Centrer</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="flex-end" id="droite" name="align-after" onChange={(e) => radiochange(e,"align-after")}/>
                            <label htmlFor="droite">Droite</label>
                        </div>
                    </div>
                    <h4>Alignement vertical du contenu</h4>
                    <div className="style">
                        <div className="align">
                            <input type="radio" value="flex-start" id="top" name="align-vertical-after" onChange={(e) => radiochange(e,"align-vertical-after")}/>
                            <label htmlFor="top">En haut</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="center" id="middle" name="align-vertical-after" onChange={(e) => radiochange(e,"align-vertical-after")}/>
                            <label htmlFor="middle">Au milieu</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="flex-end" id="bottom" name="align-vertical-after" onChange={(e) => radiochange(e,"align-vertical-after")}/>
                            <label htmlFor="bottom">En bas</label>
                        </div>
                    </div>
                    <h4>Marge du contenu</h4>
                    {getMarginSelector(15)}
                    <h4>Format de la page</h4>
                    <div className="style deux">
                        <div className="color">
                            <input type="radio" value="1" id="one" name="column-after" onChange={(e) => radiochange(e,"column-after")}/>
                            <label htmlFor="one">Une colonne</label>
                        </div>
                        <div className="color">
                            <input type="radio" value="2" id="two" name="column-after" onChange={(e) => radiochange(e,"column-after")}/>
                            <label htmlFor="two">Deux colonnes</label>
                        </div>
                    </div>
                </div>
                {column == 2 ?
                <div className="row">
                    <h4>Largeur des colonnes (en %)</h4>
                    <div className="style deux">
                        <div className="color">
                        <p>Gauche</p>
                            <InputNumber incr={() => incrementSpeed(texts[16].id,"left-column")} decr={() => decrementSpeed(texts[16].id,"left-column")} stop={stopCounter} width={texts[16].ml}/>
                        </div>
                        <div className="size">
                            <p>Droite</p>
                            <InputNumber incr={() => incrementSpeed(texts[16].id,"right-column")} decr={() => decrementSpeed(texts[16].id,"right-column")} stop={stopCounter} width={texts[16].mr}/>
                        </div>
                    </div>
                    <div className="row">
                    <h4>Placer l'image sur la colonne de:</h4>
                        <div className="style deux">
                            <div className="color">
                                <input type="radio" value="1" id="left" name="place" onChange={(e) => radiochange(e,"position-after")}/>
                                <label htmlFor="left">Gauche</label>
                            </div>
                            <div className="color">
                                <input type="radio" value="2" id="right" name="place" onChange={(e) => radiochange(e,"position-after")}/>
                                <label htmlFor="right">Droite</label>
                            </div>
                        </div>
                    </div>
                    <h4>L'image</h4>
                    <ImgUpload onChange={(e) => photoUpload(e,2)} src={images[2].imagePreviewUrl} id={images[2].id}/>
                </div> : "" }
                <div className="row border-bottom-white">
                    <p>Texte de fin de l'évènement</p>
                    <PrimaryTextarea placeholder="texte de fin de l'évènement', (ex:l'évènement est fini,..)" id={texts[15].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(15)}
                </div>
            </div>
        </div>
        )
    }
}
export default AdminAfter