import React, {Component} from "react";
import { PrimaryButton } from "./Button.style";
class Objet extends Component{
    render(){
        const {details,onDelete,type} = this.props;
        return(
        <li className="object">
        {type == "user"?
            <span>{details.email}</span>
            :<span>{details.nom}</span>
        }
        <PrimaryButton onClick={() => onDelete(details.id)}>x</PrimaryButton>
        </li>
        )
    }
}
export default Objet