import React, {Component} from "react";
import {PrimaryInput,PrimaryTextarea} from "../Input.style";
import {PrimaryButton} from "../Button.style";
import Checkbox from "../Checkbox";
import Objet from "../Objet";
import InputNumber from "../InputNumber";
import ColorPicker from "../ColorPicker";
class AdminLogin extends Component{
    render(){
        const {toggleDropdown,isOpenBody,action,texts,radiochange,getMarginSelector,column,incrementSpeed,decrementSpeed,stopCounter,photoUpload,images,addElement,getTextStyle,withLoginForm,withSigninForm,objets,compteur,changeTitle,handleChange,handleDelete,togglePopup,position} = this.props;
        const ImgUpload =({
            onChange,
            src,
            id
          })=>
            <label htmlFor={'photo-upload-'+id} className="custom-file-upload fas">
              <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src} alt=""/>
              </div>
              <input id={'photo-upload-'+id} type="file" onChange={onChange}/> 
            </label>
        return(
        <div className='dropdown'>
            <div className='dropdown-header' onClick={()=>toggleDropdown(isOpenBody,"contenu-login")}>
            Contenu de la page
            <i className={`fa fa-chevron-right icon ${isOpenBody ? 'open' : ''}`}></i>
            </div>
            <div className={!isOpenBody? 'dropdown-body' : 'dropdown-body open'}>
                <div className="row border-bottom-white">
                    <h4>Couleur de fond du contenu</h4>
                    <ColorPicker action={action} details={texts[4]}/>
                    <h4>Alignement horizontal du contenu</h4>
                    <div className="style">
                    <div className="align">
                        <input type="radio" value="flex-start" id="gauche" name="align" onChange={(e) => radiochange(e,"align")} checked={texts[3].text =="flex-start" ? true : false}/>
                        <label htmlFor="gauche">Gauche</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="center" id="center" name="align" onChange={(e) => radiochange(e,"align")}/>
                        <label htmlFor="center">Centrer</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="flex-end" id="droite" name="align" onChange={(e) => radiochange(e,"align")}/>
                        <label htmlFor="droite">Droite</label>
                    </div>
                    </div>
                    <h4>Alignement vertical du contenu</h4>
                    <div className="style">
                    <div className="align">
                        <input type="radio" value="flex-start" id="top" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical")} checked={texts[3].font =="flex-start" ? true : false}/>
                        <label htmlFor="top">En haut</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="center" id="middle" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical")}/>
                        <label htmlFor="middle">Au milieu</label>
                    </div>
                    <div className="align">
                        <input type="radio" value="flex-end" id="bottom" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical")}/>
                        <label htmlFor="bottom">En bas</label>
                    </div>
                    </div>
                    <h4>Marge du contenu</h4>
                    {getMarginSelector(5)}
                    <h4>Format de la page</h4>
                    <div className="style deux">
                    <div className="color">
                        <input type="radio" value="1" id="one" name="column" onChange={(e) => radiochange(e,"column")} checked={column ==1 ? true : false}/>
                        <label htmlFor="one">Une colonne</label>
                    </div>
                    <div className="color">
                        <input type="radio" value="2" id="two" name="column" onChange={(e) => radiochange(e,"column")}/>
                        <label htmlFor="two">Deux colonnes</label>
                    </div>
                    </div>
                </div>
                {column == 2 ?
                <div className="row">
                    <h4>Largeur des colonnes (en %)</h4>
                    <div className="style deux">
                        <div className="color">
                            <p>Gauche</p>
                            <InputNumber incr={() => incrementSpeed(texts[4].id,"left-column")} decr={() => decrementSpeed(texts[4].id,"left-column")} stop={stopCounter} width={texts[4].ml}/>
                        </div>
                        <div className="size">
                            <p>Droite</p>
                            <InputNumber incr={() => incrementSpeed(texts[4].id,"right-column")} decr={() => decrementSpeed(texts[4].id,"right-column")} stop={stopCounter} width={texts[4].mr}/>
                        </div>
                    </div>
                    <div className="row">
                    <h4>Placer l'image sur la colonne de:</h4>
                        <div className="style deux">
                            <div className="color">
                                <input type="radio" value="1" id="left" name="place" onChange={(e) => radiochange(e,"position")}/>
                                <label htmlFor="left">Gauche</label>
                            </div>
                            <div className="color">
                                <input type="radio" value="2" id="right" name="place" onChange={(e) => radiochange(e,"position")} checked={position ==2 ? true : false}/>
                                <label htmlFor="right">Droite</label>
                            </div>
                        </div>
                    </div>
                    <h4>L'image</h4>
                    <ImgUpload onChange={(e) => photoUpload(e,1)} src={images[1].imagePreviewUrl} id={images[1].id}/>
                </div> : "" }
                <Checkbox text='Ajouter un formulaire de connexion?' action={() => addElement(withLoginForm,"add-loginForm")} id="add-loginForm" checked={!withLoginForm ? true : false}/>
                {!withLoginForm ? 
                <div className="row border-bottom-white">
                    <p>Texte</p>
                    <PrimaryTextarea placeholder="texte pour se connecter, (ex:entrez votre email,..)" id={texts[5].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(5)}
                    <p class="mt-1">Texte pour le bouton de connexion</p>
                    <PrimaryInput placeholder="ex:valider, se connecter,..." id={texts[8].id} onChange={(e) => changeTitle(e)}/>
                </div> : ""}
                <Checkbox text="Ajouter un formulaire d'inscription?" action={() => addElement(withSigninForm,"add-signinForm")} id="add-signinForm" checked={!withSigninForm?true:false}/>
                {!withSigninForm ? 
                <div className="row border-bottom-white">
                    <p>Texte pour s'inscrire</p>
                    <PrimaryTextarea placeholder="texte pour s'inscrire', (ex:remplisser les champs,..)" id={texts[6].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(6)}
                    <p className="mt-1">Texte de validation</p>
                    <PrimaryTextarea placeholder="texte de validation(ex:en validant, j'accepte qu'on utilise mes données" id={texts[7].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(7)}
                    <p className="mt-1">{compteur} champ(s)</p>
                    <div class="row">
                    <ul>
                        {/* map pour boucler les elements d'un tapleau*/}
                        {objets.map(objet=> (
                        <Objet details={objet} onDelete={handleDelete}/>
                        ))}
                    </ul>
                    <PrimaryButton onClick={() => togglePopup("field")}>Ajouter un champ</PrimaryButton>
                    </div>
                    <p>Texte pour le bouton d'inscription'</p>
                    <PrimaryInput placeholder="ex:inscrire, je m'inscris,..." id={texts[9].id} onChange={(e) => changeTitle(e)}/>
                </div> : null }
                {!withLoginForm||!withSigninForm ?
                <div class="row">
                    <h4>Style des boutons et champs</h4>
                    <h5>Champ</h5>
                    <div className="style mb-1">
                        <div className="color">
                            <p>Couleur</p>
                            <ColorPicker action={handleChange} details={texts[8]}/>
                        </div>
                        <div className="size">
                            <p>taille</p>
                            <InputNumber incr={() => incrementSpeed(texts[8].id,"width")} decr={() => decrementSpeed(texts[8].id,"width")} stop={stopCounter} width={texts[8].size}/>
                        </div>
                    </div>
                    <h5>Bouton</h5>
                    <div className="style">
                    <div className="color">
                        <p>Couleur</p>
                        <ColorPicker action={action} details={texts[9]}/>
                    </div>
                    <div className="size">
                        <p>taille</p>
                        <InputNumber incr={() => incrementSpeed(texts[9].id,"width")} decr={() => decrementSpeed(texts[9].id,"width")} stop={stopCounter} width={texts[9].size}/>
                    </div>
                    </div>
                </div>
                :null}
            </div>
        </div>
        )
    }
}
export default AdminLogin