import React, {Component} from "react";
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import {PrimaryInput} from "../Input.style";
import Checkbox from "../Checkbox";
import InputNumber from "../InputNumber";
import ColorPicker from "../ColorPicker";
import { Helmet } from 'react-helmet';
class AdminHeader extends Component{
    render(){
        const {action,withMenu,separateWithBorder,withSubTitle,openMenu,getMarginSelector,addElement,incrementSpeed,decrementSpeed,stopCounter,photoUpload,images,texts,getTextStyle,withLosange,withLosangeStream,changeTitle} = this.props;
        const ImgUpload =({
            onChange,
            src,
            id
          })=>
            <label htmlFor={'photo-upload-'+id} className="custom-file-upload fas">
              <div className="img-wrap img-upload" >
                <img htmlFor="photo-upload" src={src} alt=""/>
              </div>
              <input id={'photo-upload-'+id} type="file" onChange={onChange}/> 
            </label>
        return(
            <div>
                <Helmet>
                    <title>Configurer les pages de votre évènement</title>
                </Helmet>
                <div className='dropdown'>
                    <div className='dropdown-header' onClick={() => addElement(openMenu,"menu")}>
                    Bandeau menu
                    <i className={`fa fa-chevron-right icon ${openMenu ? 'open' : ''}`}></i>
                    </div>
                    <div className={!openMenu? 'dropdown-body' : 'dropdown-body open'}>
                    <div className="row border-bottom-white">
                        <Routes>
                            <Route path='/after' element={
                            <div className="mt-1 mb-1">
                                <Checkbox text="Afficher le menu pour cette page?" action={() => addElement(withMenu,"add-menu")} id="add-menu" checked={!withMenu?true:false}/>
                            </div>} />
                        </Routes>
                        <h4>Choisissez la couleur du bandeau</h4>
                        <ColorPicker action={action} details={texts[0]}/>
                        <h4>Marge du bandeau</h4>
                        {getMarginSelector(0)}
                    </div>
                    <div className="row">
                        <h4>Ajouter le logo de l'evenement</h4>
                        <ImgUpload onChange={(e) => photoUpload(e,0)} src={images[0].imagePreviewUrl} id={images[0].id}/>
                    </div>
                    <div className="row">
                        <h4>Largeur du logo (en px)</h4>
                        <InputNumber incr={() => incrementSpeed(texts[0].id,"width")} decr={() => decrementSpeed(texts[0].id,"width")} stop={stopCounter} width={texts[0].size}/>
                    </div>
                    <div className="row border-bottom-white">
                    <h4>Marge du logo</h4>
                    {getMarginSelector(1)}
                    </div>
                    <div className="row border-bottom-white">
                        <h4>Titre de l'évènement</h4>
                        <p>Texte</p>
                        <PrimaryInput type="text" placeholder="titre de l'évènement" id={texts[1].id} onChange={(e) => changeTitle(e)}/>
                        {getTextStyle(1)}
                    </div>
                    <Checkbox text='Ajouter un sous-titre?' action={() => addElement(withSubTitle,"add-subtitle")} id="add-subtitle" checked={!withSubTitle?true:false}/>
                    {!withSubTitle ? 
                    <div className='row border-bottom-white'>
                        <h4>Sous titre de l'évènement</h4>
                        <p>Texte</p>
                        <PrimaryInput type="text" placeholder="sous-titre de l'évènement" id={texts[2].id} onChange={(e) => changeTitle(e)}/>
                        {getTextStyle(2)}
                    </div> : null }
                    <Checkbox text='Separer le titre et le logo avec une bordure?' action={() => addElement(separateWithBorder,"add-border")} id="add-border" checked={!separateWithBorder?true:false}/>
                    {!separateWithBorder ? 
                    <div className="row">
                        <div className="style three">
                        <div className="color">
                            <p>Couleur</p>
                            <ColorPicker action={action} details={texts[3]}/>
                        </div>
                        <div className="size">
                            <p>largeur</p>
                            <InputNumber incr={() => incrementSpeed(texts[3].id,"width")} decr={() => decrementSpeed(texts[3].id,"width")} stop={stopCounter} width={texts[3].size}/>
                        </div>
                        <div className="size">
                            <p>Hauteur</p>
                            <InputNumber incr={() => incrementSpeed(texts[3].id,"top")} decr={() => decrementSpeed(texts[3].id,"top")} stop={stopCounter} width={texts[3].mt}/>
                        </div>
                        </div>
                    </div> : null}
                    <div className="row border-bottom-white">
                        <h4>Marge du texte</h4>
                        {getMarginSelector(2)}
                    </div>
                    <Routes>
                        <Route path='/login' element={
                        <div>
                            <Checkbox text="Ajouter la date de l'évènement dans un losange" action={() => addElement(withLosange,"add-losange")} id="add-losange"/>
                            {!withLosange ? 
                            <div className="row">
                            <h4>Couleur de fond du losange</h4>
                            <ColorPicker action={action} details={texts[10]}/>
                            <h4>Position du losange</h4>
                            <div className="marge">
                                <div className="column">
                                <p>Haut</p>
                                <InputNumber incr={() => incrementSpeed(texts[10].id,'top')} decr={() => decrementSpeed(texts[10].id,'top')} stop={stopCounter} width={texts[10].mt}/>
                                </div>
                                <div className="column">
                                <p>Droite</p>
                                <InputNumber incr={() => incrementSpeed(texts[10].id,'right')} decr={() => decrementSpeed(texts[10].id,'right')} stop={stopCounter} width={texts[10].mr}/>
                                </div>
                            </div>
                            </div> : null}
                        </div>
                        } />
                        {!withLosange?
                        <Route path='/stream' element={
                        <Checkbox text="Reprendre le losange de la page de login?" action={() => addElement(withLosangeStream,"add-losange-stream")} id="add-losange-stream" checked={!withLosangeStream?true:false}/>}
                        />
                        :null}
                    </Routes>
                    </div>
                </div>
                <Outlet/>
                <Link to="/manager/configuration/date" style={{display:"block",width:'fit-content'}} className="PrimaryButton">Revenir à la configuration</Link>
            </div>
            )
        }
    }
    export default AdminHeader