import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import './App.css';
import Form from "./Components/Form";
import {PrimaryButton} from "./Components/Button.style";
import ColorPicker from "./Components/ColorPicker";
import Select from "./Components/Select";
import Objet from "./Components/Objet";
import InputNumber from "./Components/InputNumber";
import image from "./assets/img/upload.png";
import Login from "./Components/Login";
import Before from "./Components/Before";
import Stream from "./Components/Stream";
import After from "./Components/After";
import AdminHeader from "./Components/Admin/AdminHeader";
import AdminLogin from "./Components/Admin/AdminLogin";
import AdminBefore from "./Components/Admin/AdminBefore";
import AdminStream from "./Components/Admin/AdminStream";
import AdminAfter from "./Components/Admin/AdminAfter";
import {PrimaryInput,PrimaryTextarea} from "./Components/Input.style";
import {DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import Checkbox from "./Components/Checkbox";
import { Helmet } from 'react-helmet';
let interval;
var bgSize = "auto";
class App extends React.Component {
  state = {
    objets : [
      {id:'email' , nom: 'email', placeholder:'Email', required:false},
    ],
    texts : [
      {id:0 , text: {}, color:'#21409a',size:150,mt:0,mr:16,mb:0,ml:16,font:"",weight:0},//style menu
      {id:1 , text: 'Titre de l\'évènement',color:'#ffffff',size:36,mt:0,mr:16,mb:0,ml:0,font:"chance",weight:"medium"},//style titre
      {id:2 , text: 'Sous titre de l\'évènement',color:'#01b9ff',size:20,mt:0,mr:0,mb:0,ml:16,font:"chance",weight:"regular"},//style sous-titre
      {id:3 , text: 'flex-start',color:'#ffffff',size:1,mt:50,mr:0,mb:0,ml:0,font:"flex-start"},//style pour le border, text et font inutilisé du coup je l'utilise pour l'alignement horizontal et vertical de la page de login
      {id:4 , text: 'flex-start',color:'#ffffff',size:1,mt:0,mr:50,mb:0,ml:50,font:"flex-start"},//style des colonnes, text et font inutilisé du coup je l'utilise pour l'alignement vertical et vertical de la seconde colonnes de page de stream
      {id:5 , text: 'Texte pour se connecter',color:'#21409a',size:16,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style texte de connexion, marge inutilisé du coup de l'utilise la marge du contenu
      {id:6 , text: "Texte pour s'inscrire",color:'#21409a',size:16,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style pour le texte d'inscription
      {id:7 , text: "Texte pour de validation",color:'#21409a',size:12,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style pour le texte de validation
      {id:8 , text: "bouton",color:'#01b9ff',size:16,mt:0,mr:0,mb:0,ml:0,font:"",weight:300},//style pour le bouton de connexion, couleur inutilisé donc couleur pour les champs en general
      {id:9 , text: "bouton",color:'#ef0909',size:16,mt:0,mr:0,mb:0,ml:0,font:"",weight:300},//style pour le bouton de connexion'inscription, couleur inutilisé donc couleur pour les boutons en general
      {id:10 , text: "",color:'#01b9ff',size:0,mt:-40,mr:65,mb:0,ml:0,font:"",weight:0},//style pour le losange ml,mb inutilisé du coup utilisé pour la taille des colonnes de la page stream
      {id:11 , text: "bouton",color:'#ffffff',size:16,mt:0,mr:35,mb:0,ml:65,font:"",weight:0},// gaspillage mais l'id 10 est compliqué du coup on utilise ceci pour la taille des colonnes de la page stream
      {id:12 , text: "Texte pour envoyer une question",color:'#21409a',size:16,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style pour la page de stream
      {id:13 , text: "https://vimeo.com/event/1875062/embed",color:'flex-start',size:16,mt:0,mr:0,mb:0,ml:0,font:"flex-start",weight:0},// style pour le iframe, color et font non utilisé du coup utilisé pour le centrage du contenu
      {id:14 , text: "Bouton plein écran",color:'#21409a',size:16,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style pour le plein ecran
      {id:15 , text: "Texte de fin d'évènement",color:'#21409a',size:16,mt:0,mr:0,mb:0,ml:0,font:"chance",weight:"regular"},//style pour la page after
      {id:16 , text: "Bouton plein écran",color:'#ffffff',size:16,mt:0,mr:50,mb:0,ml:50,font:"center",weight:"center"},//alignement horizontal et vertical de la page after
      {id:17 , text: "Texte pour envoyer une question",color:'#21409a',size:16,mt:0,mr:50,mb:0,ml:50,font:"chance",weight:"regular"},//style texte de question de la page before
      {id:18 , text: "bouton",color:'#ffffff',size:16,mt:0,mr:0,mb:0,ml:0,font:"flex-start",weight:"flex-start"}//style de la page before
    ],
    images : [
      {id:0, file:'', imagePreviewUrl: image},
      {id:1, file:'', imagePreviewUrl: image},
      {id:2, file:'', imagePreviewUrl: image},
      {id:3, file:'', imagePreviewUrl: image}
    ],
    users : [
      {id:0, email:'mon-email@fdj-studio.com'},
    ],
    activeId: 0,
    compteur : 1,
    compteurUser : 1,
    withSubTitle:true,
    separateWithBorder:true,
    withLoginForm:false,
    withSigninForm:true,
    withQuestionForm:true,
    withQuestionBeforeForm:true,
    withLosange:true,
    withLosangeStream:true,
    withFullscreen:true,
    withMenu:true,
    confirm:true,
    openMenu:false,
    openBody:false,
    openBeforeBody:false,
    openStreamBody:false,
    openAfterBody:false,
    column:1,
    columnBefore:1,
    columnStream:1,
    columnAfter:1,
    position:2,
    positionBefore:2,
    positionStream:1,
    positionAfter:2,
    showPopup:false,
    dateEvent: new Date(),
    dateEventFin: new Date(),
    eventLink: '',
    eventTitle: '',
    useTitle:true,
    useDescription:true,
    titleTag: "Titre de l'évènement",
    MetaDescription: "decrire l'évènement",
    typeacces:"1",
    nbPage:"1",
    activePopup:"",
  };
  getSize(index) {
    return this.state.texts[index].size;
  }
  togglePopup(type) {
    this.setState({
      showPopup: !this.state.showPopup
    });
    if(type=="user"){
      this.setState({
        activePopup: "user"
      });
    }else if(type=="field"){
      this.setState({
        activePopup: "field"
      });
    }
  }
  getMargin(index,position) {
    switch(position){
      case 'top':
        return this.state.texts[index].mt;
      case 'left':
        return this.state.texts[index].ml;
      case 'bottom':
        return this.state.texts[index].mb;
      case 'right':
        return this.state.texts[index].mr;
      case 'left-column':
        return this.state.texts[index].ml;
      case 'right-column':
        return this.state.texts[index].mr;
    }
  }
  getFont = (id) =>{
    return this.state.texts[id].font;
  }
  getWeight = (id) =>{
    return this.state.texts[id].weight;
  }
  setOption = (selectedOption,id) => {
    const texts = this.state.texts.slice();
    texts[id].font = selectedOption;
    this.setState({texts:texts})
  }
  setAccesType = (selectedOption) => {
    this.setState({
      typeacces:selectedOption
    })
  }
  setNbPage = (selectedOption) => {
    this.setState({
      nbPage:selectedOption
    })
  }
  setOptionWeight = (selectedOption,id) => {
    const texts = this.state.texts.slice();
    texts[id].weight = selectedOption;
    this.setState({texts:texts})
  }
  handleDelete = id =>{
    {/* slice pour avoir une copie safe du tableau objets, on ne travaille jamais sur un state */}
    const objets = this.state.objets.slice();
    const index = objets.findIndex(function(objet){
      return objet.id === id
    });
    objets.splice(index, 1);
    this.setState({objets:objets});
    this.setState({compteur: this.state.compteur - 1});
  }
  handleDeleteUser = id =>{
    {/* slice pour avoir une copie safe du tableau objets, on ne travaille jamais sur un state */}
    const users = this.state.users.slice();
    const index = users.findIndex(function(user){
      return user.id === id
    });
    users.splice(index, 1);
    this.setState({users:users});
    this.setState({compteurUser: this.state.compteurUser - 1});
  }
  handleAdd = objet =>{
    const objets = this.state.objets.slice();
    this.setState({compteur: this.state.compteur + 1});
    objets.push(objet);
    this.setState({objets:objets})
  }
  handleAddUser = user =>{
    const users = this.state.users.slice();
    this.setState({compteurUser: this.state.compteurUser + 1});
    users.push(user);
    this.setState({users:users})
  }
  addElement = (value,type) => {
    var bool = value;
    if(bool){
      bool = false;
    }
    else{
      bool = true;
    }
    switch(type){
      case 'add-subtitle':
        this.setState({
          withSubTitle: bool,
        });
        break;
      case 'add-border' :
        this.setState({
          separateWithBorder: bool,
        });
        break;
      case 'add-losange' :
        this.setState({
          withLosange: bool,
        });
        break;
      case 'add-menu' :
        this.setState({
          withMenu: bool,
        });
        break;
      case 'add-losange-stream' :
        this.setState({
          withLosangeStream: bool,
        });
        break;
      case 'add-loginForm' :
        this.setState({
          withLoginForm: bool,
        });
        break;
      case 'add-signinForm' :
        this.setState({
          withSigninForm: bool,
        });
        break;
      case 'add-questionForm' :
        this.setState({
          withQuestionForm: bool,
        });
        break;
      case 'add-questionBeforeForm' :
        this.setState({
          withQuestionBeforeForm: bool,
        });
        break;
      case 'add-fullscreen' :
        this.setState({
          withFullscreen: bool,
        });
        break;
      case 'add-title' :
        this.setState({
          useTitle: bool,
        });
        break;
      case 'add-meta' :
        this.setState({
          useDescription: bool,
        });
        break;
      case 'confirmation' :
        this.setState({
          confirm: bool,
        });
        break;
      case 'menu':
        this.setState({
          openMenu : bool,
        });
        break;
      case 'contenu-login':
        this.setState({
          openBody : bool,
        });
        break;
      case 'contenu-before':
        this.setState({
          openBeforeBody : bool,
        });
        break;
      case 'contenu-stream':
        this.setState({
          openStreamBody : bool,
        });
        break;
      case 'contenu-after':
        this.setState({
          openAfterBody : bool,
        });
        break;
    }
  }
  handleChange = (e) => {
    const texts = this.state.texts.slice();
    texts[e.target.id].color = e.target.value;
    if(e.target.id===0){
      this.setState({
        texts : texts,
      });
    }else{
      this.setState({
        texts : texts,
      });
    }
  };
  photoUpload = (e,id) =>{
    e.preventDefault();
    const images = this.state.images.slice();
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log(reader.result);
    reader.onloadend = () => {
      images[id].file = file ;
      images[id].imagePreviewUrl = reader.result;
      this.setState({
        images: images 
      });
    }
    reader.readAsDataURL(file);
    if(id === 1||id === 2){
      bgSize = "cover";
    }
  }
  incrementSpeed = (id,type) => {
    const texts = this.state.texts.slice();
    var max;
    interval = setInterval(() => {
      if(type!=="right-column"&&type!=="left-column"&&type!=="right-column-stream"&&type!=="left-column-stream"){
        max  = 400;
      }else{
        max = 100;
      }
      if (this.getSize(id) >= max || this.getMargin(id,type) >= max) return;
      switch(type){
        case 'width':
          texts[id].size = this.getSize(id) + 1;
          break;
        case 'top':
          texts[id].mt = this.getMargin(id,'top') + 1;
          break;
        case 'left':
          texts[id].ml = this.getMargin(id,'left') + 1;
          break;
        case 'bottom':
          texts[id].mb = this.getMargin(id,'bottom') + 1;
          break;
        case 'right':
          texts[id].mr = this.getMargin(id,'right') + 1;
          break;
        case 'right-column':
          texts[id].mr = this.getMargin(id,'right-column') + 1;
          texts[id].ml = this.getMargin(id,'left-column') - 1;
          break;
        case 'left-column':
          texts[id].ml = this.getMargin(id,'left-column') + 1;
          texts[id].mr = this.getMargin(id,'right-column') - 1;
          break;
      }
      this.setState({ texts : texts });
    }, 50);
  };
  decrementSpeed = (id,type) => {
    const texts = this.state.texts.slice();
    interval = setInterval(() => {
      if(id!=10){
        var min  = 0;
        if (this.getSize(id) <= min || this.getMargin(id,type)<= min) return; // getsize retourne 0 pour 10 du coup la decrementation ne marche pas
      }
      switch(type){
        case 'width':
          texts[id].size = this.getSize(id) - 1;
          break;
        case 'top':
          texts[id].mt = this.getMargin(id,'top') - 1;
          break;
        case 'left':
          texts[id].ml = this.getMargin(id,'left') - 1;
          break;
        case 'bottom':
          texts[id].mb = this.getMargin(id,'bottom') - 1;
          break;
        case 'right':
          texts[id].mr = this.getMargin(id,'right') - 1;
          break;
        case 'right-column':
          texts[id].mr = this.getMargin(id,'right-column') - 1;
          texts[id].ml = this.getMargin(id,'left-column') + 1;
          break;
        case 'left-column':
          texts[id].ml = this.getMargin(id,'left-column') - 1;
          texts[id].mr = this.getMargin(id,'right-column') + 1;
          break;
      }
      this.setState({ texts : texts });
    }, 50);
  };
  stopCounter = () => {
    clearInterval(interval);
  };
  changeTitle = (e,type) =>{
    if(type =="title"){
      var text = e.target.value;
      this.setState({
        titleTag: text
      })
    }
    else{
      const texts = this.state.texts.slice();
      texts[e.target.id].text = e.target.value;
      this.setState({
        texts : texts,
      })
    }
  }
  radiochange = (e,type) =>{
    const column = e.target.value;
    const texts = this.state.texts.slice();
    switch(type){
      case 'column':
        this.setState({
          column : column,
        });
        break;
      case 'column-before':
        this.setState({
          columnBefore : column,
        });
        break;
      case 'column-stream':
        this.setState({
          columnStream : column,
        });
        break;
      case 'column-after':
        this.setState({
          columnAfter : column,
        });
        break;
      case 'position':
        this.setState({
          position : column,
        });
        break;
      case 'position-before':
        this.setState({
          positionBefore : column,
        });
        break;
      case 'position-stream':
        this.setState({
          positionStream : column,
        });
        break;
      case 'position-after':
        this.setState({
          positionAfter : column,
        });
        break;
      case 'align':
        texts[3].text = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-vertical':
        texts[3].font = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-before':
        texts[18].font = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-vertical-before':
        texts[18].weight = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-stream':
        texts[13].color = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-vertical-stream':
        texts[13].font = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-stream-second':
        texts[4].text = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-vertical-stream-second':
        texts[4].font = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-after':
        texts[16].font = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
      case 'align-vertical-after':
        texts[16].weight = e.target.value;
        this.setState({
          texts : texts,
        });
        break;
    }
  }
  getDate = (e,type) =>{
    var date = e.target.value;
    if(type=="debut"){
      this.setState({
        dateEvent : date
      });
    }else{
      this.setState({
        dateEventFin : date
      });
    }
  }
  render() {
    const title = "FDJ Studio";
    const options = {
      roboto: 'Roboto Condensed',
      calson: 'CaslonGraphiqueEF',
      chance: 'Chance'
    }
    const weights = {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700
    }
    const configs = {
      1: "Page d'attente + page de live + page after + page replay",
      2: "Page d'attente + page de live + page after",
      3: "Page d'attente + page de live",
      4: "Page de live + page after",
      5: "page de live"
    }
    const access = {
      1: "Tout le monde accède sans identification",
      2: "Toute personne qui indique son email",
      3: "Une liste de personne prédefinie",
      4: "Toute personne dont la forme de l'email est @monsite.com",
      5: "Okta"
    }
    const getMarginSelector = (id) =>{
      return(
        <div className="marge">
          <div className="column">
            <p>Haut</p>
            <InputNumber incr={() => this.incrementSpeed(this.state.texts[id].id,'top')} decr={() => this.decrementSpeed(this.state.texts[id].id,'top')} stop={this.stopCounter} width={this.getMargin(id,'top')}/>
          </div>
          <div className="column">
            <p>Gauche</p>
            <InputNumber incr={() => this.incrementSpeed(this.state.texts[id].id,'left')} decr={() => this.decrementSpeed(this.state.texts[id].id,'left')} stop={this.stopCounter} width={this.getMargin(id,'left')}/>
          </div>
          <div className="column">
            <p>Bas</p>
            <InputNumber incr={() => this.incrementSpeed(this.state.texts[id].id,'bottom')} decr={() => this.decrementSpeed(this.state.texts[id].id,'bottom')} stop={this.stopCounter} width={this.getMargin(id,'bottom')}/>
          </div>
          <div className="column">
            <p>Droite</p>
            <InputNumber incr={() => this.incrementSpeed(this.state.texts[id].id,'right')} decr={() => this.decrementSpeed(this.state.texts[id].id,'right')} stop={this.stopCounter} width={this.getMargin(id,'right')}/>
          </div>
        </div>
      )
    }
    const getTextStyle = (id) =>{
      return(
        <div>
          <div className="style mb-1">
            <div className="color">
              <p>Couleur</p>
              <ColorPicker action={this.handleChange} details={this.state.texts[id]}/>
            </div>
            <div className="size">
              <p>taille</p>
              <InputNumber incr={() => this.incrementSpeed(this.state.texts[id].id,"width")} decr={() => this.decrementSpeed(this.state.texts[id].id,"width")} stop={this.stopCounter} width={this.getSize(id)}/>
            </div>
            <div className="weight">
              <p>Epaisseur</p>
              <Select onChange={this.setOptionWeight} id={this.state.texts[id].id} options={weights} selectedOption={this.getWeight(id)}/>
            </div>
          </div>
          <p>Police</p>
          <Select onChange={this.setOption} id={this.state.texts[id].id} options={options} selectedOption={this.getFont(id)}/>
        </div>
      )
    }
    return (
      <Router>
      <div className="App">
        <Routes>
        <Route path='/manager/*' element={
        <div className="SideBar">
          <h1 style={{textAlign:"center"}}>{title}</h1>
          <div className="scroll">
            <Routes>
            <Route path='/configuration/*' element={
              <div>
                <NavLink to="/manager/configuration/date" className={({ isActive }) => (isActive ? 'dropdown active' : 'dropdown')}><span className="info">1 - Date</span></NavLink>
                <NavLink to="/manager/configuration/url" className={({ isActive }) => (isActive ? 'dropdown active' : 'dropdown')}><span className="info">2 - Url</span></NavLink>
                <NavLink to="/manager/configuration/referencement" className={({ isActive }) => (isActive ? 'dropdown active' : 'dropdown')}><span className="info">3 - Référencement</span></NavLink>
                <NavLink to="/manager/configuration/reglage" className={({ isActive }) => (isActive ? 'dropdown active' : 'dropdown')}><span className="info">4 - Configuration</span></NavLink>
                <Link to={this.state.typeacces!="1"&&this.state.typeacces!="5"?'/manager/customize-page/login':'/manager/customize-page/stream'} className="dropdown"><span className="info">5 - Customiser les pages</span></Link>
              </div>
            }/>
            <Route path='/customize-page' element={
              <AdminHeader 
                getMarginSelector = {getMarginSelector}
                getTextStyle ={getTextStyle}
                texts = {this.state.texts}
                images = {this.state.images}
                openMenu = {this.state.openMenu}
                addElement ={this.addElement}
                withMenu = {this.state.withMenu}
                withSubTitle = {this.state.withSubTitle}
                separateWithBorder = {this.state.separateWithBorder}
                incrementSpeed = {this.incrementSpeed}
                decrementSpeed = {this.decrementSpeed}
                stopCounter = {this.stopCounter}
                photoUpload = {this.photoUpload}
                changeTitle = {this.changeTitle}
                action = {this.handleChange}
                withLosange = {this.state.withLosange}
                withLosangeStream = {this.state.withLosangeStream}
              />}>
                <Route path='/customize-page/login' element={<AdminLogin
                  toggleDropdown = {this.addElement}
                  isOpenBody = {this.state.openBody}
                  action = {this.handleChange}
                  texts = {this.state.texts}
                  position = {this.state.position}
                  radiochange = {this.radiochange}
                  getMarginSelector = {getMarginSelector}
                  column = {this.state.column}
                  incrementSpeed = {this.incrementSpeed}
                  decrementSpeed = {this.decrementSpeed}
                  stopCounter = {this.stopCounter}
                  photoUpload = {this.photoUpload}
                  images = {this.state.images}
                  addElement ={this.addElement}
                  getTextStyle ={getTextStyle}
                  withLoginForm={this.state.withLoginForm}
                  withSigninForm={this.state.withSigninForm}
                  objets={this.state.objets}
                  compteur = {this.state.compteur}
                  changeTitle = {this.changeTitle}
                  handleChange = {this.handleChange}
                  handleDelete = {this.handleDelete}
                  togglePopup = {() => this.togglePopup()}
                />} />
                <Route path='/customize-page/before' element={<AdminBefore
                  toggleDropdown = {this.addElement}
                  isOpenBeforeBody = {this.state.openBeforeBody}
                  action = {this.handleChange}
                  texts = {this.state.texts}
                  position = {this.state.positionBefore}
                  radiochange = {this.radiochange}
                  getMarginSelector = {getMarginSelector}
                  column = {this.state.columnBefore}
                  incrementSpeed = {this.incrementSpeed}
                  decrementSpeed = {this.decrementSpeed}
                  stopCounter = {this.stopCounter}
                  photoUpload = {this.photoUpload}
                  images = {this.state.images}
                  addElement ={this.addElement}
                  getTextStyle ={getTextStyle}
                  withQuestionForm={this.state.withQuestionBeforeForm}
                  objets={this.state.objets}
                  compteur = {this.state.compteur}
                  changeTitle = {this.changeTitle}
                  handleChange = {this.handleChange}
                  handleDelete = {this.handleDelete}
                  togglePopup = {() => this.togglePopup()}
                />} />
                <Route path='/customize-page/stream' element={<AdminStream
                  toggleDropdown = {this.addElement}
                  isOpenStreamBody = {this.state.openStreamBody}
                  action = {this.handleChange}
                  texts = {this.state.texts}
                  radiochange = {this.radiochange}
                  getMarginSelector = {getMarginSelector}
                  column = {this.state.columnStream}
                  positionStream = {this.state.positionStream}
                  incrementSpeed = {this.incrementSpeed}
                  decrementSpeed = {this.decrementSpeed}
                  stopCounter = {this.stopCounter}
                  photoUpload = {this.photoUpload}
                  images = {this.state.images}
                  addElement ={this.addElement}
                  getTextStyle ={getTextStyle}
                  withQuestionForm={this.state.withQuestionForm}
                  withFullscreen={this.state.withFullscreen}
                  changeTitle = {this.changeTitle}
                  handleChange = {this.handleChange}
                  togglePopup = {() => this.togglePopup()}
                />} />
                <Route path='/customize-page/after' element={<AdminAfter
                  toggleDropdown = {this.addElement}
                  isOpenBodyAfter = {this.state.openAfterBody}
                  action = {this.handleChange}
                  texts = {this.state.texts}
                  radiochange = {this.radiochange}
                  getMarginSelector = {getMarginSelector}
                  column = {this.state.columnAfter}
                  incrementSpeed = {this.incrementSpeed}
                  decrementSpeed = {this.decrementSpeed}
                  stopCounter = {this.stopCounter}
                  photoUpload = {this.photoUpload}
                  images = {this.state.images}
                  addElement ={this.addElement}
                  getTextStyle ={getTextStyle}
                  changeTitle = {this.changeTitle}
                  handleChange = {this.handleChange}
                  handleDelete = {this.handleDelete}
                />} />
              </Route>
            </Routes>
          </div>
        </div>}/>
        </Routes>
        <Routes>
        <Route path='/manager/*' element={
        <div className="Content">
          <Routes>
            <Route path='/configuration/date' element={
              <div className="configuration-content">
                <Helmet>
                  <title>configurer la date de l'évènement</title>
                </Helmet>
                <div className="configuration-content-header">
                  <h3>Etape 1 - Date</h3>
                </div>
                <div className="configuration-content-body">
                  <p>Choisir la date et heure de l'évènement</p>
                  <DateTimePickerComponent id="datetimepicker" onChange={(e) => this.getDate(e,"debut")} value={this.state.dateEvent}/>
                  <p className="mt-1">Choisir la date et heure de fin de l'évènement</p>
                  <DateTimePickerComponent id="datetimepickerfin" onChange={(e) => this.getDate(e,"fin")} value={this.state.dateEventFin}/>
                  <span className="note">*Ces dates ont modifiables à tout moment</span>
                  <Link to={'/manager/configuration/url'} className="PrimaryButton">Suivant</Link> 
                </div>
              </div>
            }/>
            <Route path='/configuration/url' element={
              <div className="configuration-content">
                <Helmet>
                  <title>configurer l'URL de l'évènement</title>
                </Helmet>
                <div className="configuration-content-header">
                  <h3>Etape 2 - URL</h3>
                </div>
                <div className="configuration-content-body">
                  <p>Entrez le titre de l'évènement</p>
                  <PrimaryInput style={{fontSize:16}} type="text" name="titre" placeholder="le titre de votre évènement" id="titre" required={true}/>
                  <p>Taper l'url de votre évènement (ex: fdj.itislive.com)</p>
                  <PrimaryInput style={{fontSize:16}} type="text" name="url" placeholder="le lien de votre évènement" id="url" required={true}/>
                  <span className="note">*Pas d'éspace ni de caractère speciaux</span>
                  <p>Choisir le format de l'url</p>
                  <div className="style">
                    <div className="color mb-1">
                        <input type="radio" value="0" id="format-0" name="format-url"/>
                        <label htmlFor="format-0">https://fdj-studio.itislive.com/client/event</label>
                    </div>
                    <div className="color mb-1">
                        <input type="radio" value="1" id="format-1" name="format-url" />
                        <label htmlFor="format-1">https://fdj-studio.itislive.com/event</label>
                    </div>
                  </div>
                  <div className="button-action">
                    <Link to={'/manager/configuration/date'} className="PrimaryButton">Retour</Link>
                    <Link to={'/manager/configuration/referencement'} className="PrimaryButton">Suivant</Link>
                  </div>
                </div>
              </div>
            }/>
            <Route path='/configuration/referencement' element={
              <div className="configuration-content">
                <Helmet>
                  <title>configurer la référencement de l'évènement</title>
                </Helmet>
                <div className="configuration-content-header">
                  <h3>Etape 3 - Référencement</h3>
                </div>
                <div className="configuration-content-body">
                  <Checkbox text="Reprendre le titre de l'évènement pour le titre de la page?" action={() => this.addElement(this.state.useTitle,"add-title")} id="add-title" checked={!this.state.useTitle ? true : false}/>
                  {this.state.useTitle ?
                  <div>
                    <p>Entrer le titre pour la page</p>
                    <PrimaryInput style={{fontSize:16}} type="text" name="titre" placeholder="le titre de la page" id="titre" required={true} onChange={(e) => this.changeTitle(e,"title")}/>
                  </div>
                  :null}
                  <Checkbox text="Referencer la page sur google?" action={() => this.addElement(this.state.useDescription,"add-meta")} id="add-meta" checked={!this.state.useDescription ? true : false}/>
                  {!this.state.useDescription ?
                  <div>
                    <p>Entrer la description la page</p>
                    <PrimaryTextarea style={{fontSize:16}} type="text" name="titre" placeholder="le titre de la page" id="meta" required={true}/>
                  </div>
                  :null}
                  <div className="button-action">
                    <Link to={'/manager/configuration/url'} className="PrimaryButton">Retour</Link>
                    <Link to={'/manager/configuration/reglage'} className="PrimaryButton">Suivant</Link>
                  </div>
                </div>
              </div>
            }/>
            <Route path='/configuration/reglage' element={
              <div className="configuration-content">
                <Helmet>
                  <title>configurer les details de l'évènement</title>
                </Helmet>
                <div className="configuration-content-header">
                  <h3>Etape 4 - Configuration</h3>
                </div>
                <div className="configuration-content-body">
                  <p>Configurer votre évènement</p>
                  <Select onChange={this.setNbPage}  id="page" options={configs} selectedOption="1"/>
                  <p className="mt-1">Définissez l'accès et les contrôles à votre événement</p>
                  <Select onChange={this.setAccesType}  id="access-type" options={access} selectedOption="1"/>
                  {this.state.typeacces == "4"?
                  <div className="type-bordered">
                    <p className="mt-1">Entrez la forme de l'email(à partir de "@", ex : @yahoo.com, etc)</p>
                    <PrimaryInput style={{fontSize:16}} type="text" name="titre" placeholder="ex:@yahoo.com" id="titre" required={true}/>
                  </div>
                  :this.state.typeacces=="3"?
                  <div className="type-bordered">
                    <p className="mt-1">{this.state.compteurUser} utilisateur(s)</p>
                    <div class="row">
                    <ul>
                        {/* map pour boucler les elements d'un tapleau*/}
                        {this.state.users.map(user=> (
                        <Objet details={user} onDelete={this.handleDeleteUser} type="user"/>
                        ))}
                      </ul>
                      <PrimaryButton onClick={() => this.togglePopup("user")}>Ajouter un utilisateur</PrimaryButton>
                    </div>
                  </div>
                  :null}
                  <div className="button-action mt-1">
                    <Link to={'/manager/configuration/referencement'} className="PrimaryButton">Retour</Link>
                    <Link to={'/manager/customize-page/login'} className="PrimaryButton">Suivant</Link>
                  </div>
                </div>
              </div>
            }/>
            {this.state.typeacces!="1"&&this.state.typeacces!="5"?
            <Route path='/customize-page/login' element={<Login 
              dateEvent = {this.state.dateEvent}
              dateEventFin = {this.state.dateEventFin}
              separateWithBorder = {this.state.separateWithBorder}
              withSubTitle = {this.state.withSubTitle}
              options ={options}
              weights = {weights}
              bgSize = {bgSize}
              position = {this.state.position}
              column = {this.state.column}
              withLoginForm = {this.state.withLoginForm}
              withSigninForm = {this.state.withSigninForm}
              withLosange = {this.state.withLosange}
              logo = {this.state.images[0].imagePreviewUrl}
              fond = {this.state.images[1].imagePreviewUrl}
              texts = {this.state.texts}
              objets = {this.state.objets}
              actionCheckbox = {() => this.addElement(this.state.confirm,"confirmation")}
              confirm = {this.state.confirm}
              />} />
            :null}
            <Route path='/customize-page/before' element={<Before 
              dateEvent = {this.state.dateEvent}
              dateEventFin = {this.state.dateEventFin}
              separateWithBorder = {this.state.separateWithBorder}
              withSubTitle = {this.state.withSubTitle}
              options ={options}
              weights = {weights}
              bgSize = {bgSize}
              position = {this.state.positionBefore}
              column = {this.state.columnBefore}
              withQuestionForm = {this.state.withQuestionBeforeForm}
              withLosange = {this.state.withLosange}
              logo = {this.state.images[0].imagePreviewUrl}
              fond = {this.state.images[3].imagePreviewUrl}
              texts = {this.state.texts}
              objets = {this.state.objets}
              actionCheckbox = {() => this.addElement(this.state.confirm,"confirmation")}
              confirm = {this.state.confirm}
            />} />
            <Route path='/customize-page/stream' element={<Stream
              dateEvent = {this.state.dateEvent}
              dateEventFin = {this.state.dateEventFin}
              separateWithBorder = {this.state.separateWithBorder}
              withSubTitle = {this.state.withSubTitle}
              options ={options}
              weights = {weights}
              bgSize = {bgSize}
              position = {this.state.positionStream}
              column = {this.state.columnStream}
              withQuestionForm = {this.state.withQuestionForm}
              withLosange = {this.state.withLosange}
              withLosangeStream = {this.state.withLosangeStream}
              withFullscreen={this.state.withFullscreen}
              logo = {this.state.images[0].imagePreviewUrl}
              fond = {this.state.images[1].imagePreviewUrl}
              texts = {this.state.texts}
              />} />
            {this.state.nbPage!="3"&&this.state.nbPage!="5"?
            <Route path='/customize-page/after' element={<After 
              separateWithBorder = {this.state.separateWithBorder}
              withSubTitle = {this.state.withSubTitle}
              options ={options}
              weights = {weights}
              bgSize = {bgSize}
              position = {this.state.positionAfter}
              column = {this.state.columnAfter}
              withMenu = {this.state.withMenu}
              logo = {this.state.images[0].imagePreviewUrl}
              fond = {this.state.images[2].imagePreviewUrl}
              texts = {this.state.texts}
              />} />
            :null}
          </Routes>
          {this.state.showPopup ? 
            <div className='popup'>
              <div className='popup_inner'>
                <div className="popup-header">
                  <h4>Ajouter un champ</h4>
                  <PrimaryButton onClick={() => this.togglePopup()}>X</PrimaryButton>
                </div>
                <div className="popup-body">
                  {this.state.activePopup=="field"?
                  <Form onAdd={this.handleAdd} onAddUser={this.handleAddUser} type="add-field"/>
                  :this.state.activePopup=="user"?
                  <Form onAdd={this.handleAdd} onAddUser={this.handleAddUser} type="add-user"/>
                  :null}
                </div>
              </div>
            </div>
            :null
          }
        </div>}/>
        <Route path='/' element={<Login 
          dateEvent = {this.state.dateEvent}
          dateEventFin = {this.state.dateEventFin}
          separateWithBorder = {this.state.separateWithBorder}
          withSubTitle = {this.state.withSubTitle}
          titleTag = {this.state.titleTag}
          options ={options}
          weights = {weights}
          bgSize = {bgSize}
          position = {this.state.position}
          column = {this.state.column}
          withLoginForm = {this.state.withLoginForm}
          withSigninForm = {this.state.withSigninForm}
          withLosange = {this.state.withLosange}
          logo = {this.state.images[0].imagePreviewUrl}
          fond = {this.state.images[1].imagePreviewUrl}
          texts = {this.state.texts}
          objets = {this.state.objets}
          actionCheckbox = {() => this.addElement(this.state.confirm,"confirmation")}
          confirm = {this.state.confirm}
          />} />
        <Route path='/before' element={<Before 
          dateEvent = {this.state.dateEvent}
          dateEventFin = {this.state.dateEventFin}
          separateWithBorder = {this.state.separateWithBorder}
          withSubTitle = {this.state.withSubTitle}
          titleTag = {this.state.titleTag}
          options ={options}
          weights = {weights}
          bgSize = {bgSize}
          position = {this.state.position}
          column = {this.state.column}
          withLoginForm = {this.state.withLoginForm}
          withSigninForm = {this.state.withSigninForm}
          withLosange = {this.state.withLosange}
          logo = {this.state.images[0].imagePreviewUrl}
          fond = {this.state.images[1].imagePreviewUrl}
          texts = {this.state.texts}
          objets = {this.state.objets}
          actionCheckbox = {() => this.addElement(this.state.confirm,"confirmation")}
          confirm = {this.state.confirm}
          />} />
        <Route path='/stream' element={<Stream
          dateEvent = {this.state.dateEvent}
          dateEventFin = {this.state.dateEventFin}
          separateWithBorder = {this.state.separateWithBorder}
          withSubTitle = {this.state.withSubTitle}
          titleTag = {this.state.titleTag}
          options ={options}
          weights = {weights}
          bgSize = {bgSize}
          position = {this.state.positionStream}
          column = {this.state.columnStream}
          withQuestionForm = {this.state.withQuestionForm}
          withLosange = {this.state.withLosange}
          withLosangeStream = {this.state.withLosangeStream}
          withFullscreen={this.state.withFullscreen}
          logo = {this.state.images[0].imagePreviewUrl}
          fond = {this.state.images[1].imagePreviewUrl}
          texts = {this.state.texts}
          />} />
        <Route path='/after' element={<After 
          separateWithBorder = {this.state.separateWithBorder}
          withSubTitle = {this.state.withSubTitle}
          titleTag = {this.state.titleTag}
          options ={options}
          weights = {weights}
          bgSize = {bgSize}
          position = {this.state.positionAfter}
          column = {this.state.columnAfter}
          withMenu = {this.state.withMenu}
          logo = {this.state.images[0].imagePreviewUrl}
          fond = {this.state.images[2].imagePreviewUrl}
          texts = {this.state.texts}
          />} />
        </Routes>
        <Routes>
          <Route path='/manager/customize-page/*' element={
          <div id="mySidenav">
            <Routes>
            <Route path='/login' element={
              <Link to={'/'} id="view"> <i class="fa fa-eye" aria-hidden="true"></i> Voir</Link>
            }/>
            <Route path='/before' element={
              <Link to={'/before'} id="view"> <i class="fa fa-eye" aria-hidden="true"></i> Voir</Link>
            }/>
            <Route path='/stream' element={
              <Link to={'/stream'} id="view"> <i class="fa fa-eye" aria-hidden="true"></i> Voir</Link>
            }/>
            <Route path='/after' element={
              <Link to={'/after'} id="view"> <i class="fa fa-eye" aria-hidden="true"></i> Voir</Link>
            }/>
            </Routes>
            {this.state.typeacces!="1"&&this.state.typeacces!="5"?
            <Link to={'/manager/customize-page/login'} id="login"> <i className="fa fa-sign-in"></i> Login</Link>
            :null}
            <Link to={'/manager/customize-page/before'} id="before"> <i className="fa fa-sign-in"></i> Before</Link>
            <Link to={'/manager/customize-page/stream'} id="stream"><i className="fa fa-desktop"></i> Stream</Link>
            {this.state.nbPage!="3"&&this.state.nbPage!="5"?
            <Link to={'/manager/customize-page/after'} id="after"><i className="fa fa-check-circle"></i> Fin</Link>
            :null}
          </div>
          }/>
        </Routes>
      </div>
    </Router>
    )
  }
}
export default App
