import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
class After extends Component {
    getTextAlign(align){
        if(align==="flex-start"){
          return "left";
        }
        else if(align==="center"){
          return "center";
        }else {
          return "right";
        }
    }
    getAlign(align){
        if(align==="flex-start"){
          return "0 auto 16px 0";
        }
        else if(align==="center"){
          return "0 auto 16px auto";
        }else {
          return "0 0 16px auto";
        }
    }
    render() {
    const {separateWithBorder,withSubTitle,options,weights,bgSize,position,column,fond,logo,texts,withMenu,titleTag} = this.props;
    const getText = (id) =>{ return(<p style={{color:texts[id].color,fontSize:texts[id].size,fontFamily:options[texts[id].font],fontWeight:weights[texts[id].weight]}}>{texts[id].text}</p>)};
    const styleTextContent= (pos) => {
      var width;
      if(pos == "left"){
        width = texts[16].mr
      }
      else{
        width = texts[16].ml
      }
      return(
      {
        width:width+'%',
        paddingTop: texts[15].mt,
        paddingLeft: texts[15].ml,
        paddingBottom: texts[15].mb,
        paddingRight: texts[15].mr,
        display:"flex",
        flexDirection:"column",
        alignItems:texts[16].font,
        textAlign:this.getTextAlign(texts[16].weight),
        justifyContent:texts[16].weight
      }
      )
    }
    var secondColumn = 
    <div className="second-column" style={position == 2 ? {
      width:texts[16].mr+'%',
      backgroundImage:`url(${fond}`,
      backgroundRepeat:"no-repeat",
      backgroundPosition:'center',
      backgroundSize:bgSize} : styleTextContent("left")}>
      {column == 2&&position==1 ? getText(15) : "" }
    </div>;
    var firstWidth = {width:texts[16].ml+'%',backgroundImage:`url(${fond}`,backgroundRepeat:"no-repeat",backgroundPosition:'center',backgroundSize:bgSize};
    return (
        <div className="after">
          <Helmet>
          <title>{titleTag?"Fin de l'évènement | "+titleTag:"Configurer la page after"}</title>
          </Helmet>
            <div id="mySidenav">
              <Link to={'/manager/customize-page/after'} id="view"> <i class="fa fa-edit" aria-hidden="true"></i> Editer</Link>
            </div>
            {!withMenu ? 
            <div className="header" style={{ background: texts[0].color,paddingTop: texts[0].mt,paddingLeft: texts[0].ml,paddingBottom: texts[0].mb,paddingRight: texts[0].mr }}>
                <div className="logo" style={{ paddingTop: texts[1].mt,paddingLeft: texts[1].ml,paddingBottom: texts[1].mb,paddingRight: texts[1].mr }}>
                <img htmlFor="photo-upload" src={logo}  alt="" style={{width: texts[0].size}}/>
                </div> 
                {(function() {
                    if (separateWithBorder) {
                    return null;
                    } else {
                    return <div style={{backgroundColor: texts[3].color,width:texts[3].size,height:texts[3].mt,position:"relative",zIndex:1}}></div>;
                    }
                })()}
                <div className="text" style={{ paddingTop: texts[2].mt,paddingLeft: texts[2].ml,paddingBottom: texts[2].mb,paddingRight: texts[2].mr }}>
                <h1 style={{color:texts[1].color,fontSize:texts[1].size,fontFamily:options[texts[1].font],fontWeight:weights[texts[1].weight]}}>{texts[1].text}</h1>
                {(function() {
                    if (withSubTitle) {
                    return null;
                    } else {
                    return <h2 style={{color:texts[2].color,fontSize:texts[2].size,fontFamily:options[texts[2].font],fontWeight:weights[texts[2].weight]}}>{texts[2].text}</h2>;
                    }
                })()}
                </div>
            </div>
            : "" }
            <div className="section" style={{backgroundColor:texts[16].color}}>
                <div className={column == 2 ? "first-column" : "first-column only"} style={column == 2 && position == 1? firstWidth : styleTextContent("right")}>
                {(column == 1)||(column == 2&&position ==2) ? getText(15) : ""}
                </div>
                {(function() {
                if (column == 2) {
                    return secondColumn;
                } else {
                    return null;
                }
                })()}
            </div>    
        </div>
    );
  }
}

export default After;