import React ,{Component} from "react";
class InputNumber extends Component{
    render(){
        return(
        <div className="input-number">
            <span>{this.props.width}</span>
            <div className="action">
              <button type="button" onMouseDown={this.props.incr} onMouseUp={this.props.stop}>&#43;</button>  
              <button type="button" onMouseDown={this.props.decr} onMouseUp={this.props.stop}>&minus;</button>
            </div> 
          </div>
        )
    }
}
export default InputNumber