import React ,{Component} from "react";
class Checkbox extends Component{
    render(){
        const {text,action,id,labelStyle,squareStyle,checked} = this.props;
        return(
        <div className="form-group">
            <input type="checkbox" id={id} onChange={action} checked={checked}/>
            <label htmlFor={id}><span className="square" style={squareStyle}></span><span style={labelStyle}>{text}</span></label>
        </div>
        )
    }
}
export default Checkbox