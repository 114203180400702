import React ,{Component} from "react";
import {PrimaryButton} from "./Button.style";
import {PrimaryInput} from "./Input.style";
class Form extends Component{
    state = {
        objetName:"",
        objetId:"",
        objetPlaceholder:'',
        objetRequired: false,
        email:""
    }
    handleChange = (event,type) =>{
        switch(type){
            case "name" :
                this.setState({objetName: event.currentTarget.value});
                break;
            case "id" :
                this.setState({objetId: event.currentTarget.value});
                break;
            case "placeholder" :
                this.setState({objetPlaceholder: event.currentTarget.value});
                break;
            case "yes" :
                this.setState({objetRequired: true});
                break;
            case "no" :
                this.setState({objetRequired: false});
                break;
            case "email" :
                this.setState({email: event.currentTarget.value});
                break;
        }
      
    }
    handleSubmit = (event,type) => {
        event.preventDefault();
        const id = this.state.objetId;
        const nom = this.state.objetName;
        const placeholder = this.state.objetPlaceholder;
        const required = this.state.objetRequired;
        const email =  this.state.email;
        if(type=="add-field"){
            this.props.onAdd({id,nom,placeholder,required});
            this.setState({
                objetName:"",
                objetId:"",
                objetPlaceholder:""
            });
        }else if(type=="add-user"){
            this.props.onAddUser({id,email});
            this.setState({
                email:""
            })
        }
    }
    render(){
        const {type} = this.props;
        return(
            <form onSubmit={(e) => this.handleSubmit(e,type)}>
                {type == "add-field"?
                <div>
                    <p>Attribut "name" du champ (veuillez le mettre en anglais pour facilement reconnaitre les données ex:"name" pour nom) </p>
                    <PrimaryInput value={this.state.objetName} type="text" onChange={(e) => this.handleChange(e,"name")} required={true}/>
                    <p>Attribut "id" du champ (unique pour chaque champ et sans espace ni caractère speciaux) </p>
                    <PrimaryInput value={this.state.objetId} type="text" onChange={(e) => this.handleChange(e,"id")} required={true}/>
                    <p>Le texte pour que l'utisateur puisse connaitre le champ à remplir (ex:entrer votre email,...)</p>
                    <PrimaryInput value={this.state.objetPlaceholder} type="text" onChange={(e) => this.handleChange(e,"placeholder")} required={true}/>
                    <p>Ce champ est-il obligatoire?</p>
                    <div className="style">
                        <div className="color">
                            <input type="radio" value="0" id="yes" name="required" onChange={(e) => this.handleChange(e,"yes")}/>
                            <label htmlFor="yes">Oui</label>
                        </div>
                        <div className="color">
                            <input type="radio" value="1" id="no" name="required" onChange={(e) => this.handleChange(e,"no")}/>
                            <label htmlFor="no">Nom</label>
                        </div>
                    </div>
                    <PrimaryButton>Ajouter</PrimaryButton>
                </div> 
                :type=="add-user"?
                <div>
                    <p>Inserer l'email de l'utilisateur </p>
                    <PrimaryInput value={this.state.email} type="email" onChange={(e) => this.handleChange(e,"email")} required={true}/>
                    <PrimaryButton>Ajouter</PrimaryButton>
                </div>
                :null}
            </form>
        )
    }
}
export default Form