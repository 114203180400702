import React, { Component } from 'react';
import {PrimaryInput, PrimaryTextarea} from "./Input.style";
import {PrimaryButton} from "./Button.style";
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
class Stream extends Component {
    state = {
      showFullscreen : false,
      questionOpen : false
    }
    getTextAlign(align){
        if(align=="flex-start"){
          return "left";
        }
        else if(align=="center"){
          return "center";
        }else {
          return "right";
        }
    }
    togglePopup() {
      this.setState({
        showFullscreen: !this.state.showFullscreen
      });
    }
    openForm = () =>{
      this.setState({
        questionOpen: true
      });
    } 
    sendQuestion = (e) =>{
      e.preventDefault();
      this.setState({
        questionOpen: false
      });
    }
    render() {
    const {separateWithBorder,withSubTitle,options,weights,position,column,withQuestionForm,withFullscreen,logo,texts,withLosange,withLosangeStream,titleTag} = this.props;
    var StyleInput = {
        fontSize:texts[8].size,
        backgroundColor:texts[8].color,
        display:'block'
    }
    var StyleButton = {
        fontSize:texts[9].size,
        backgroundColor:texts[9].color,
    }
    const getText = (id) =>{ return(<p style={{color:texts[id].color,fontSize:texts[id].size,fontFamily:options[texts[id].font],fontWeight:weights[texts[id].weight]}}>{texts[id].text}</p>)};
    const getFullscreenBtn = () =>{ return(<p style={{color:texts[14].color,fontSize:texts[14].size,fontFamily:options[texts[14].font],fontWeight:weights[texts[14].weight],marginTop:"1rem",cursor:"pointer"}} onClick={() => this.togglePopup()}>{texts[14].text}</p>)};
    const getSigninForm = () =>{return(
      <form class="question-form">
        <PrimaryTextarea style={StyleInput} name="question"  id="question" required={true}/>
        <PrimaryButton style={StyleButton}>{texts[11].text}</PrimaryButton>
      </form>
    )}
    const styleTextContent= (pos) => {
      var pt,pl,pb,pr,width,alignItems,textAlign,justifyContent;
      if(pos == "left"){
        width = texts[11].mr;
        pt = texts[13].mt;
        pl = texts[13].ml;
        pb = texts[13].mb;
        pr = texts[1].mr;
        alignItems = texts[4].text;
        textAlign = this.getTextAlign(texts[4].text);
        justifyContent = texts[4].font;
      }
      else{
        width = texts[11].ml;
        pt = texts[12].mt;
        pl = texts[12].ml;
        pb = texts[12].mb;
        pr = texts[12].mr;
        alignItems = texts[13].color;
        textAlign = this.getTextAlign(texts[13].color);
        justifyContent = texts[13].font;
      }
      return(
      {
        width:width+'%',
        paddingTop: pt,
        paddingLeft: pl,
        paddingBottom: pb,
        paddingRight: pr,
        display:"flex",
        flexDirection:"column",
        alignItems: alignItems,
        textAlign: textAlign,
        justifyContent: justifyContent
      }
      )
    }
    var secondColumn = 
    <div className="second-column" style={position == 2 ? {
      width:texts[11].mr+'%',
      paddingTop: texts[13].mt,
      paddingLeft: texts[13].ml,
      paddingBottom: texts[13].mb,
      paddingRight: texts[13].mr,
      display:"flex",
      flexDirection:"column",
      alignItems: texts[4].text,
      textAlign: this.getTextAlign(texts[4].text),
      justifyContent: texts[4].font
      } : styleTextContent("left")}>
      {column == 2&&position==1&&!withQuestionForm ? getText(12) : "" }
      {column == 2&&position==1&&!withQuestionForm ? getSigninForm() : ""}
      {(column == 2&&position ==2) ? <div className="player"><div className="iframe-wrapper"><iframe src={texts[13].text} frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe></div></div> : ""}
    </div>;
    var firstWidth = {
      width:texts[11].ml+'%',
      paddingTop: texts[12].mt,
      paddingLeft: texts[12].ml,
      paddingBottom: texts[12].mb,
      paddingRight: texts[12].mr,
      display:"flex",
      flexDirection:"column",
      alignItems:texts[13].color,
      textAlign:this.getTextAlign(texts[13].color),
      justifyContent:texts[13].font
    };
    return (
        <div className="stream" style={{position:"relative"}}>
          <Helmet>
            <title>{titleTag?"Stream | "+titleTag:"Configurer la page de stream"}</title>
          </Helmet>
            <div id="mySidenav">
              <Link to={'/manager/customize-page/stream'} id="view"> <i class="fa fa-edit" aria-hidden="true"></i> Editer</Link>
            </div>
            <div className="header" style={{ background: texts[0].color,paddingTop: texts[0].mt,paddingLeft: texts[0].ml,paddingBottom: texts[0].mb,paddingRight: texts[0].mr }}>
                <div className="logo" style={{ paddingTop: texts[1].mt,paddingLeft: texts[1].ml,paddingBottom: texts[1].mb,paddingRight: texts[1].mr }}>
                <img htmlFor="photo-upload" src={logo}  alt="" style={{width: texts[0].size}}/>
                </div> 
                {(function() {
                    if (separateWithBorder) {
                    return null;
                    } else {
                    return <div style={{backgroundColor: texts[3].color,width:texts[3].size,height:texts[3].mt,position:"relative",zIndex:1}}></div>;
                    }
                })()}
                <div className="text" style={{ paddingTop: texts[2].mt,paddingLeft: texts[2].ml,paddingBottom: texts[2].mb,paddingRight: texts[2].mr }}>
                <h1 style={{color:texts[1].color,fontSize:texts[1].size,fontFamily:options[texts[1].font],fontWeight:weights[texts[1].weight]}}>{texts[1].text}</h1>
                {(function() {
                    if (withSubTitle) {
                    return null;
                    } else {
                    return <h2 style={{color:texts[2].color,fontSize:texts[2].size,fontFamily:options[texts[2].font],fontWeight:weights[texts[2].weight]}}>{texts[2].text}</h2>;
                    }
                })()}
                </div>
            </div>
            <div className="section" style={{backgroundColor:texts[11].color}}>
                {!withLosange&&!withLosangeStream ? 
                <div style={{position:"absolute",zIndex:1,right:texts[10].mr,top:texts[10].mt}}>
                    <div className="contenu">
                        <div className="losange-body" style={{backgroundColor:texts[10].color}}></div>
                        <div className="text">
                        <p className="grand">16 mars</p>
                        <p className="petit">16h - 18h30</p>
                        <p className="heure">(heure de paris)</p>
                        </div>
                    </div>
                </div>
                :""}
                <div className={column == 2 ? "first-column" : "first-column only"} style={column == 2&&position == 1? firstWidth : styleTextContent("right")}>
                {(column == 2&&position ==2&&!withQuestionForm) ? getText(12) : ""}
                {(column == 2&&position ==2&&!withQuestionForm) ? getSigninForm() : ""}
                {(column == 1)||(column == 2&&position ==1) ? <div className="player"><div className="iframe-wrapper"><iframe src={texts[13].text} frameborder="0" allow="autoplay; fullscreen" allowfullscreen=""></iframe></div></div> : ""}
                {(column == 1&&!withFullscreen)||(column == 2&&position ==1&&!withFullscreen) ? getFullscreenBtn() : null}
                </div>
                {(function() {
                if (column == 2) {
                    return secondColumn;
                } else {
                    return null;
                }
                })()}
            </div>  
            {this.state.showFullscreen? 
            <div className='popup-fullscreen'>
              <p className="close" style={{left:texts[14].ml}} onClick={() => this.togglePopup()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M14.434,18.655v8.665c0,0.678-0.547,1.227-1.227,1.227c-0.678,0-1.227-0.549-1.227-1.227v-5.707l-9.889,9.892  c-0.24,0.239-0.553,0.358-0.867,0.358s-0.627-0.119-0.865-0.358c-0.479-0.479-0.479-1.255,0-1.733l9.891-9.891H4.543  c-0.678,0-1.227-0.549-1.227-1.226s0.549-1.226,1.227-1.226h8.664C13.887,17.43,14.434,17.979,14.434,18.655z M18.654,14.706h8.666  c0.678,0,1.227-0.549,1.227-1.226s-0.549-1.226-1.227-1.226h-5.707L31.641,2.229c0.479-0.479,0.479-1.254,0-1.733  c-0.479-0.479-1.254-0.479-1.732,0L19.881,10.522V4.815c0-0.677-0.549-1.226-1.227-1.226c-0.676,0-1.225,0.549-1.225,1.226v8.665  C17.43,14.157,17.979,14.706,18.654,14.706z M21.75,19.881h5.707c0.678,0,1.225-0.549,1.225-1.226s-0.547-1.226-1.225-1.226h-8.664  c-0.68,0-1.227,0.549-1.227,1.226v8.665c0,0.678,0.547,1.227,1.227,1.227c0.676,0,1.225-0.549,1.225-1.227v-5.707l9.891,9.892  c0.24,0.239,0.553,0.358,0.865,0.358c0.314,0,0.627-0.119,0.867-0.358c0.479-0.479,0.479-1.255,0-1.733L21.75,19.881z M13.344,3.59  c-0.676,0-1.225,0.549-1.225,1.226v5.707L2.092,0.495c-0.479-0.479-1.256-0.479-1.732,0c-0.479,0.479-0.479,1.254,0,1.733  l10.025,10.026H4.68c-0.678,0-1.227,0.549-1.227,1.226s0.549,1.226,1.227,1.226h8.664c0.678,0,1.227-0.549,1.227-1.226V4.815  C14.57,4.139,14.021,3.59,13.344,3.59z"></path></svg></p>
              <div className="player">
                <div className="iframe-wrapper" style={{marginTop:texts[14].mt,marginLeft:texts[14].ml,marginBottom:texts[14].mb,marginRight:texts[14].mr}}>
                  {!withQuestionForm?
                  <form className={!this.state.questionOpen?"fullscreenForm":"fullscreenForm open"} onSubmit={(e) => this.sendQuestion(e)}>
                    <div className="content">
                      <PrimaryInput style={StyleInput} placeholder="votre question" required={true}/>
                      <PrimaryButton style={StyleButton}>{texts[11].text}</PrimaryButton>
                      <span style={StyleButton} onClick={() => this.openForm()}>{texts[11].text}</span>
                    </div>
                  </form>
                  :null}
                  <iframe src={texts[13].text} frameborder="0" allow="autoplay; fullscreen" allowfullscreen="">
                  </iframe>
                </div>
              </div>
            </div>
            :null} 
        </div>
    );
  }
}

export default Stream;