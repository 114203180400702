import React, {Component} from "react";
import {PrimaryInput,PrimaryTextarea} from "../Input.style";
import Checkbox from "../Checkbox";
import InputNumber from "../InputNumber";
import ColorPicker from "../ColorPicker";
class AdminStream extends Component{
    render(){
        const {toggleDropdown,isOpenStreamBody,action,texts,radiochange,getMarginSelector,column,incrementSpeed,decrementSpeed,stopCounter,addElement,getTextStyle,withQuestionForm,withFullscreen,changeTitle,positionStream} = this.props;
        return(
        <div className='dropdown'>
            <div className='dropdown-header' onClick={()=>toggleDropdown(isOpenStreamBody,"contenu-stream")}>
            Contenu de la page
            <i className={`fa fa-chevron-right icon ${isOpenStreamBody ? 'open' : ''}`}></i>
            </div>
            <div className={!isOpenStreamBody? 'dropdown-body' : 'dropdown-body open'}>
                <div className="row border-bottom-white">
                    <h4>Couleur de fond du contenu</h4>
                    <ColorPicker action={action} details={texts[11]}/>
                    <h4>Alignement horizontal du contenu</h4>
                    <div className="style">
                        <div className="align">
                            <input type="radio" value="flex-start" id="gauche" name="align" onChange={(e) => radiochange(e,"align-stream")}/>
                            <label htmlFor="gauche">Gauche</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="center" id="center" name="align" onChange={(e) => radiochange(e,"align-stream")}/>
                            <label htmlFor="center">Centrer</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="flex-end" id="droite" name="align" onChange={(e) => radiochange(e,"align-stream")}/>
                            <label htmlFor="droite">Droite</label>
                        </div>
                    </div>
                    <h4>Alignement vertical du contenu</h4>
                    <div className="style">
                        <div className="align">
                            <input type="radio" value="flex-start" id="top" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-stream")}/>
                            <label htmlFor="top">En haut</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="center" id="middle" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-stream")}/>
                            <label htmlFor="middle">Au milieu</label>
                        </div>
                        <div className="align">
                            <input type="radio" value="flex-end" id="bottom" name="align-vertical" onChange={(e) => radiochange(e,"align-vertical-stream")}/>
                            <label htmlFor="bottom">En bas</label>
                        </div>
                    </div>
                    <h4>Marge du contenu</h4>
                    {getMarginSelector(12)}
                    <h4>Le lien de la video</h4>
                    <PrimaryInput placeholder="ex: https://vimeo.com/event/embed" id={texts[13].id} onChange={(e) => changeTitle(e)}/>
                    <Checkbox text="Activer le plein ecran?" action={() => addElement(withFullscreen,"add-fullscreen")} id="add-fullscreen" checked={!withFullscreen?true:false}/>
                    {!withFullscreen ?
                    <div className="row border-bottom-white">
                    <p>Texte pour le bouton plein écran</p>
                    <PrimaryInput placeholder="(ex:voir en plein écran,..)" id={texts[14].id} onChange={(e) => changeTitle(e)}/>
                    {getTextStyle(14)}
                    <h4>Marge du plein écran</h4>
                    {getMarginSelector(14)}
                    </div>
                    : ""
                    }
                    <h4>Format de la page</h4>
                    <div className="style deux">
                        <div className="color">
                            <input type="radio" value="1" id="one" name="column" onChange={(e) => radiochange(e,"column-stream")} checked={column ==1 ? true : false}/>
                            <label htmlFor="one">Une colonne</label>
                        </div>
                        <div className="color">
                            <input type="radio" value="2" id="two" name="column" onChange={(e) => radiochange(e,"column-stream")}/>
                            <label htmlFor="two">Deux colonnes</label>
                        </div>
                    </div>
                </div>
                {column == 2 ?
                <div className="row">
                    <h4>Largeur des colonnes (en %)</h4>
                    <div className="style deux">
                        <div className="color">
                        <p>Gauche</p>
                            <InputNumber incr={() => incrementSpeed(texts[11].id,"left-column")} decr={() => decrementSpeed(texts[11].id,"left-column")} stop={stopCounter} width={texts[11].ml}/>
                        </div>
                        <div className="size">
                            <p>Droite</p>
                            <InputNumber incr={() => incrementSpeed(texts[11].id,"right-column")} decr={() => decrementSpeed(texts[11].id,"right-column")} stop={stopCounter} width={texts[11].mr}/>
                        </div>
                    </div>
                    <div className="row">
                    <h4>Placer la video sur la colonne de:</h4>
                        <div className="style deux">
                            <div className="color">
                                <input type="radio" value="1" id="left" name="place" onChange={(e) => radiochange(e,"position-stream")} checked={positionStream == 1? true: false}/>
                                <label htmlFor="left">Gauche</label>
                            </div>
                            <div className="color">
                                <input type="radio" value="2" id="right" name="place" onChange={(e) => radiochange(e,"position-stream")}/>
                                <label htmlFor="right">Droite</label>
                            </div>
                        </div>
                    </div>
                    <Checkbox text="Ajouter un formulaire pour poser des questions?" action={() => addElement(withQuestionForm,"add-questionForm")} id="add-questionForm" checked={!withQuestionForm?true:false}/>
                    {!withQuestionForm ? 
                    <div className="row border-bottom-white">
                        <p>Texte pour pour poser une question</p>
                        <PrimaryTextarea placeholder="texte pour une question', (ex:posez votre question,..)" id={texts[12].id} onChange={(e) => changeTitle(e)}/>
                        {getTextStyle(12)}
                        <p className="mt-1">Texte pour le bouton d'envoi une question</p>
                        <PrimaryInput placeholder="ex:envoyer, valider,..." id={texts[11].id} onChange={(e) => changeTitle(e)}/>
                        <h4>Alignement horizontal de la deuxieme colonne</h4>
                        <div className="style">
                            <div className="align">
                                <input type="radio" value="flex-start" id="gauche-second" name="align-stream-second" onChange={(e) => radiochange(e,"align-stream-second")} checked={texts[4].text =="flex-start" ? true : false}/>
                                <label htmlFor="gauche-second">Gauche</label>
                            </div>
                            <div className="align">
                                <input type="radio" value="center" id="center-second" name="align-stream-second" onChange={(e) => radiochange(e,"align-stream-second")}/>
                                <label htmlFor="center-second">Centrer</label>
                            </div>
                            <div className="align">
                                <input type="radio" value="flex-end" id="droite-second" name="align-stream-second" onChange={(e) => radiochange(e,"align-stream-second")}/>
                                <label htmlFor="droite-second">Droite</label>
                            </div>
                        </div>
                        <h4>Alignement vertical de la deuxieme colonne</h4>
                        <div className="style">
                            <div className="align">
                                <input type="radio" value="flex-start" id="top-second" name="align-vertical-stream-second" onChange={(e) => radiochange(e,"align-vertical-stream-second")} checked={texts[4].font =="flex-start" ? true : false}/>
                                <label htmlFor="top-second">En haut</label>
                            </div>
                            <div className="align">
                                <input type="radio" value="center" id="middle-second" name="align-vertical-stream-second" onChange={(e) => radiochange(e,"align-vertical-stream-second")}/>
                                <label htmlFor="middle-second">Au milieu</label>
                            </div>
                            <div className="align">
                                <input type="radio" value="flex-end" id="bottom-second" name="align-vertical-stream-second" onChange={(e) => radiochange(e,"align-vertical-stream-second")}/>
                                <label htmlFor="bottom-second">En bas</label>
                            </div>
                        </div>
                        <h4>Marge du contenu</h4>
                        {getMarginSelector(13)}
                    </div>
                     : "" }
                </div> : "" }
            </div>
        </div>
        )
    }
}
export default AdminStream