import React, { Component } from 'react';
import {PrimaryInput} from "./Input.style";
import {PrimaryButton} from "./Button.style";
import Checkbox from "./Checkbox";
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
class Login extends Component {
    getTextAlign(align){
        if(align=="flex-start"){
          return "left";
        }
        else if(align=="center"){
          return "center";
        }else {
          return "right";
        }
    }
    getAlign(align){
        if(align=="flex-start"){
          return "0 auto 16px 0";
        }
        else if(align=="center"){
          return "0 auto 16px auto";
        }else {
          return "0 0 16px auto";
        }
    }
    render() {
    const {separateWithBorder,withSubTitle,options,weights,bgSize,position,column,withLoginForm,withSigninForm,fond,logo,texts,objets,withLosange,actionCheckbox,confirm,dateEvent,dateEventFin,titleTag} = this.props;
    var StyleInput = {
        fontSize:texts[8].size,
        backgroundColor:texts[8].color,
        margin:this.getAlign(texts[3].text)
    }
    var StyleButton = {
        fontSize:texts[9].size,
        backgroundColor:texts[9].color,
    }
    const getText = (id) =>{ return(<p style={{color:texts[id].color,fontSize:texts[id].size,fontFamily:options[texts[id].font],fontWeight:weights[texts[id].weight]}}>{texts[id].text}</p>)};
    const getLoginForm = () =>{return(
        <form className="connexion-form">
        <PrimaryInput type="email" style={StyleInput} placeholder="entrer votre email" id={texts[2].id}/>
        <PrimaryButton style={StyleButton}>{texts[8].text}</PrimaryButton></form>
    )};
    const getSigninForm = () =>{return(
      <form class="signin-form">{objets.map(objet=> (
        <PrimaryInput style={StyleInput} type="text" name={objet.nom} placeholder={objet.placeholder} id={objet.id} required={objet.required}/>
      ))}
      <Checkbox squareStyle={{backgroundColor:texts[9].color}} text={texts[7].text} labelStyle={{fontSize:texts[7].size,color:texts[7].color,fontFamily:options[texts[7].font],fontWeight:weights[texts[7].weight]}} action={actionCheckbox} id="confirmation"/>
      <PrimaryButton style={StyleButton} disabled={confirm}>{texts[9].text}</PrimaryButton>
      </form>
    )}
    const getMois = (mois) =>{
      switch(mois){
        case "01":
          return "Jan";
        case "02":
          return "Fev"
        case "03":
          return "Mar"
        case "04":
          return "Avr"
        case "05":
          return "Mai"
        case "06":
          return "Juin";
        case "07":
          return "Jul"
        case "08":
          return "Aoû"
        case "09":
          return "Sept"
        case "10":
          return "Oct"
        case "11":
          return "Nov"
        case "12":
          return "Dec"
      }
    }
    const styleTextContent= (pos) => {
      var width;
      if(pos == "left"){
        width = texts[4].mr
      }
      else{
        width = texts[4].ml
      }
      return(
      {
        width:width+'%',
        paddingTop: texts[5].mt,
        paddingLeft: texts[5].ml,
        paddingBottom: texts[5].mb,
        paddingRight: texts[5].mr,
        display:"flex",
        flexDirection:"column",
        alignItems:texts[3].text,
        textAlign:this.getTextAlign(texts[3].text),
        justifyContent:texts[3].font
      }
      )
    }
    var secondColumn = 
    <div className="second-column" style={position == 2 ? {
      width:texts[4].mr+'%',
      backgroundImage:`url(${fond}`,
      backgroundRepeat:"no-repeat",
      backgroundPosition:'center',
      backgroundSize:bgSize} : styleTextContent("left")}>
      {column == 2&&position==1&&!withLoginForm ? getText(5) : "" }
      {column == 2&&position==1&&!withLoginForm ? getLoginForm() : "" }
      {column == 2&&position==1&&!withSigninForm ? getText(6) : "" }
      {column == 2&&position==1&&!withSigninForm ? getSigninForm() : ""}
    </div>;
    var firstWidth = {width:texts[4].ml+'%',backgroundImage:`url(${fond}`,backgroundRepeat:"no-repeat",backgroundPosition:'center',backgroundSize:bgSize};
    return (
        <div className="login">
          <Helmet>
            <title> {titleTag?"Connectez-vous | "+titleTag:"Configurer la page de connexion"}</title>
          </Helmet>
            <div id="mySidenav">
                <Link to={'/manager/customize-page/login'} id="view"> <i class="fa fa-edit" aria-hidden="true"></i> Editer</Link>
            </div>
            <div className="header" style={{ background:texts[0].color,paddingTop: texts[0].mt,paddingLeft: texts[0].ml,paddingBottom: texts[0].mb,paddingRight: texts[0].mr }}>
                <div className="logo" style={{ paddingTop: texts[1].mt,paddingLeft: texts[1].ml,paddingBottom: texts[1].mb,paddingRight: texts[1].mr }}>
                <img htmlFor="photo-upload" src={logo}  alt="" style={{width: texts[0].size}}/>
                </div> 
                {(function() {
                    if (separateWithBorder) {
                    return null;
                    } else {
                    return <div style={{backgroundColor: texts[3].color,width:texts[3].size,height:texts[3].mt,position:"relative",zIndex:1}}></div>;
                    }
                })()}
                <div className="text" style={{ paddingTop: texts[2].mt,paddingLeft: texts[2].ml,paddingBottom: texts[2].mb,paddingRight: texts[2].mr }}>
                  <h1 style={{color:texts[1].color,fontSize:texts[1].size,fontFamily:options[texts[1].font],fontWeight:weights[texts[1].weight]}}>{texts[1].text}</h1>
                  {(function() {
                      if (withSubTitle) {
                      return null;
                      } else {
                      return <h2 style={{color:texts[2].color,fontSize:texts[2].size,fontFamily:options[texts[2].font],fontWeight:weights[texts[2].weight]}}>{texts[2].text}</h2>;
                      }
                  })()}
                </div>
            </div>
            <div className="section" style={{backgroundColor:texts[4].color}}>
                {!withLosange ? 
                <div style={{position:"absolute",zIndex:1,right:texts[10].mr,top:texts[10].mt}}>
                    <div className="contenu">
                        <div className="losange-body" style={{backgroundColor:texts[10].color}}></div>
                        <div className="text">
                        <p className="grand">{dateEvent!=null?String(dateEvent.getDate()).padStart(2, '0'):null} {dateEvent!=null?getMois(String(dateEvent.getMonth() + 1).padStart(2, '0')):null}</p>
                        <p className="petit">{dateEvent!=null?dateEvent.getHours()+"h":null}{(dateEvent!=null&&dateEvent.getMinutes()!="0")?dateEvent.getMinutes():null} - {dateEventFin!=null?dateEventFin.getHours()+"h":null}{(dateEventFin!=null&&dateEventFin.getMinutes()!="0")?dateEventFin.getMinutes():null}</p>
                        <p className="heure">(heure de paris)</p>
                        </div>
                    </div>
                </div>
                :null}
                <div className={column == 2 ? "first-column" : "first-column only"} style={column == 2 && position == 1? firstWidth : styleTextContent("right")}>
                {(column == 1&&!withLoginForm)||(column == 2&&position ==2&&!withLoginForm) ? getText(5) : ""}
                {(column == 1&&!withLoginForm)||(column == 2&&position ==2&&!withLoginForm) ? getLoginForm() : ""}
                {(column == 1&&!withSigninForm)||(column == 2&&position ==2&&!withSigninForm) ? getText(6) : ""}
                {(column == 1&&!withSigninForm)||(column == 2&&position ==2&&!withSigninForm) ? getSigninForm() : ""}
                </div>
                {(function() {
                if (column == 2) {
                    return secondColumn;
                } else {
                    return null;
                }
                })()}
            </div>    
        </div>
    );
  }
}

export default Login;