import React ,{Component} from "react";
class ColorPicker extends Component{
    render(){
        const {details,action} = this.props;
        return(
        <label className="color-selector">
            <span className="circle" style={{background:details.color}} />
            <span>{details.color}</span>
            <input
                type="color"
                value={details.color}
                onChange={action}
                className="hidden"
                id={details.id}
            />
        </label>
        )
    }
}
export default ColorPicker